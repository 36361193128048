// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;( function( $, window, document, undefined ) {

	"use strict";

		// undefined is used here as the undefined global variable in ECMAScript 3 is
		// mutable (ie. it can be changed by someone else). undefined isn't really being
		// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
		// can no longer be modified.

		// window and document are passed through as local variable rather than global
		// as this (slightly) quickens the resolution process and can be more efficiently
		// minified (especially when both are regularly referenced in your plugin).

		// Create the defaults once
		var pluginName = "master";

		// The actual plugin constructor
		function Plugin ( element, options ) {
			this.element = element;
			this.$element = $(element);
			this._name = pluginName;
			this.init();
		}

		// Avoid Plugin.prototype conflicts
		$.extend( Plugin.prototype, {
			init: function() {
				this.$element.unbind('change').on('change', function(e) {
/*					if($(this).val() == '') {
						return false;
					}*/

					var masterMode = $(this).data("mode")?$(this).data("mode"):"refresh";
                                        var masterValue = $(this).val();

					if(masterMode=="refresh") {
						console.log('refreshing');
						var slaveId = $(this).data("slave");
						var lovName = $("#" + slaveId).data("lov-type");
						var data = "lov=" + lovName + "&" + $(this).closest('form').serialize();

						$.ajax({
							url : '!w_va_md_form.lovparam',
							type : 'POST',
							data : data,
							success : function(data) {
								data = JSON.parse(data);
								$("#" + slaveId).find('option')
								.remove()
								$.each(data, function(key, value) {   
									$("#" + slaveId).append($("<option value=" + key + "></option>").text(value)); 
								});

								if($("#" + slaveId).data("value") != "")
								{
									$("#" + slaveId).val($("#" + slaveId).data("value")).attr("selected", "selected");
									$("#" + slaveId).val($("#" + slaveId).data("value")).change();

								}

							}
						});
					} else if (masterMode=="disable") {
						$.each($(this).data(), function(i, v) {
							if (i.match("enableCondition"))
							{
								if(masterValue==v)
									$("#" + i.replace("enableCondition", "").toUpperCase()).prop('disabled', false);
								else
									$("#" + i.replace("enableCondition", "").toUpperCase()).prop('disabled', true);
							}
						});

					}

				}).trigger('change');
			}
		});

		// A really lightweight plugin wrapper around the constructor,
		// preventing against multiple instantiations
		$.fn[ pluginName ] = function( options ) {
			return this.each( function() {
				if ( !$.data( this, "plugin_" + pluginName ) ) {
					$.data( this, "plugin_" +
						pluginName, new Plugin( this, options ) );
				}
			} );
		};

	} )( jQuery, window, document );
