(function($){
    $.fn.tableExpander = function(settings){
		var defaults = {  
			showMore : 'Show More',
			showLess : 'Show Less'
		};  
		settings = $.extend(defaults, settings);
		tablecounter = 0;
		
		return this.each(function() {
			tablecounter++;

			var element = this;
			var numRows = parseInt($(this).data("numrows"));
			var morelink = "more"+tablecounter;
			var lesslink = "less"+tablecounter;

            var rowCount = $(element).find('tbody').find('tr').length;

            if(rowCount > numRows)
            {
    		   if($(element).find('tfoot').length == 0)
			   {
				   $(element).find('tbody').after('<tfoot><tr id="'+morelink+'" class="showMoreTable"><td colspan=10>' + defaults.showMore  + ' (' + (rowCount-numRows)+ ' more)</td></tr>'+
			                                          '<tr style="display:none;" id="'+lesslink+'" class="showLessTable"><td colspan=10 >' + defaults.showLess  + '</td></tr></tfoot>');
			   }

				$(element).find('tbody > tr').show().end();
			
				if($('#'+morelink).is(':visible'))
					$(element).find('tbody > tr:gt(' + (numRows-1) + ')').hide().end();
			   
			   $('#'+morelink).click(function(){
			     $(element).find('tbody > tr:gt(' + (numRows-1) + ')').show().end();
			     $('#'+morelink).hide();
			     $('#'+lesslink).hide();
			     $('#'+lesslink).show();
			    })	

			   $('#'+lesslink).click(function(){
			     $(element).find('tbody > tr:gt(' + (numRows-1) + ')').hide().end();
			     $('#'+morelink).hide();
			     $('#'+lesslink).hide();
			     $('#'+morelink).show();
			    })
                         }
        })
    }    
})(jQuery); 
