(function ($) {

  $.fn.filterTable = function ( id ) {

    var filterID = id;
    var table = $(this);
	var tableId = $(this).attr("id");
	var fieldId = id;

	// Check if an ID is there and if not assign it
	if(!tableId)
	{
		tableId = Math.floor((Math.random() * 100000) + 1).toString();
		$(this).attr("id", tableId);
	}

    if (!fieldId) {
      fieldId = tableId + "_filter";
	  $("#" + tableId).before("<input id='" + fieldId + "' placeholder='Type to filter' class='pull-right' type='text'/>");
	  filterID = "#" + fieldId;
    }
    
    $(filterID).on("keyup", function() {
      var value = $(this).val().toUpperCase();

      table.find('tr').each(function(index) {

        $row = $(this);
        var bool = false;

        $row.find("td").each(function() {
          $cell = $(this).text().toUpperCase();
          if ($cell.indexOf(value) > -1) {  
              bool = true;
          }
        });

        if (bool) {  
          $row.show();
        }
        else {
          $row.hide();
        }
      }); // table each
	  
	  if(!value)
	  {
		  $('.paginatedtable').tablePagination();
		  $('.expandingtable').tableExpander();		  
	  }

    }); // search keyup

    return this;
  }

})(jQuery);